<template>
    <main class="d-flex justify-content-center align-items-center hv-100 ml-3 mr-3">
        <div class="text-center">
            <span class="text-warning display-1">
                <i class="fab fa-connectdevelop"></i>
            </span>
            <a class="btn btn-warning btn-block text-uppercase mt-3" :href="link" @click="close">Abrir aplicativo</a>
            <template v-if="message">
                <div class="alert alert-danger mt-3" role="alert">
                    <span>{{message}}</span>
                </div>
            </template>
            <template v-else>
                <div class="alert alert-secondary mt-3" role="alert">
                    <span>Clique em <strong>ABRIR APLICATIVO</strong> para validar a pré-aposta rapidamente</span>
                </div>
            </template>
        </div>
        <nav class="navbar fixed-bottom navbar-light bg-dark">
            <div class="row w-100">
                <div class="col text-right pr-0">
                    <small class="text-white">Desenvolvido por</small>
                </div>
                <div class="col text-left pl-0">
                    <a href="https://betsnow.net">
                        <img src="https://demo.betsnow.net/images/logo.png" height="30" alt="BetsNOW">
                    </a>
                </div>
            </div>
        </nav>
    </main>
</template>

<script>
export default {
    data() {
        return {
            link: '',
            message: ''
        }
    },
    methods: {
        close() {
            setTimeout(() => {
                window.location.href = '/';
            }, 3000);
        }
    },
    created() {
        try {

            let code = this.$route.params.code.replace(/[^0-9]/g, '');
            
            if (code == '' || code.toString().length < 6 || code.toString().length > 6) {
                this.message = 'O código fornecido é inválido!';
            } else {
                this.link = `betsnet://prebet/${code}`;
            }
        } 
        catch(e) 
        {
            this.message = 'O link informado é inválido!';
        }
    }
}
</script>

<style scoped>
.hv-100 {
    height: 100vh;
}
</style>