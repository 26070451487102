<template>
    <main>
        <Navbar />
        <div class="main">
            <div class="container">
                <div v-show="loading == true" class="alert alert-warning" role="alert">
                    <div class="alert-heading text-center mb-0">
                        <div class="spinner-border mr-2 align-middle" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <span>Carregando, aguarde...</span>
                    </div>
                </div><!-- /alert-warning -->
                <div v-show="error.length > 0" class="alert alert-danger" role="alert">
                    <h4 class="alert-heading">Ops, ocorreu um problema!</h4>
                    <hr />
                    <p class="mb-0">{{error}}</p>
                </div><!-- /alert-danger -->
                <div v-show="markets.length > 0">
                    <div class="btn-group w-100" role="group">
                        <button type="button" class="btn btn-secondary" @click="cat = '90'" :class="{'active': cat == '90'}" :disabled="cat == '90' || sport > 1">
                            <i class="fas fa-clock mr-2" v-show="platform == 'desktop'"></i>
                            <span v-if="platform == 'desktop'">90 minutos</span>
                            <span v-else>90 min</span>
                        </button>
                        <button type="button" class="btn btn-secondary" @click="cat = 'pt'" :class="{'active': cat == 'pt'}" :disabled="cat == 'pt' || sport > 1">
                            <i class="fas fa-clock mr-2" v-show="platform == 'desktop'"></i>
                            <span v-if="platform == 'desktop'">Primeiro tempo</span>
                            <span v-else>1º tempo</span>
                        </button>
                        <button type="button" class="btn btn-secondary" @click="cat = 'st'" :class="{'active': cat == 'st'}" :disabled="cat == 'st' || sport > 1">
                            <i class="fas fa-clock mr-2" v-show="platform == 'desktop'"></i>
                            <span v-if="platform == 'desktop'">Segundo tempo</span>
                            <span v-else>2º tempo</span>
                        </button>
                    </div><!-- /btn-group -->
                    <div class="row ml-0 mt-3 mb-3 mr-0">
                        <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                            <select class="form-control" :class="{'mb-3': platform == 'mobile'}" v-model="sport">
                                <option value="1" :selected="sport == 1">Futebol</option>
                                <option value="2" :selected="sport == 2">Basquete</option>
                                <option value="3" :selected="sport == 3">Lutas</option>
                                <option value="4" :selected="sport == 4">Hóquei</option>
                                <option value="5" :selected="sport == 5">Voleibol</option>
                                <option value="6" :selected="sport == 6">Tênis</option>
                                <option value="7" :selected="sport == 7">E-Sports</option>
                            </select>
                        </div>
                        <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                            <input class="form-control" :class="{'mb-3': platform == 'mobile'}" type="text" v-model="search" placeholder="Buscar opção...">
                        </div>
                        <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                            <button class="btn btn-danger btn-block" :class="{'mb-3': platform == 'mobile'}" @click="controlGeneral">
                                <i class="fas fa-cogs mr-2"></i>Controle geral
                            </button>
                        </div><!-- /col -->
                        <div class="col pl-0 pr-0" :class="{'d-contents': platform == 'mobile'}">
                            <button class="btn btn-warning btn-block" @click="save">
                                <i class="fas fa-save mr-2"></i>Salvar alterações
                            </button>
                        </div><!-- /col -->
                    </div><!-- /row -->
                    <div class="mt-3 mb-3">
                        <div class="table-responsive">
                            <table class="table table-striped table-dark table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">Opção</th>
                                        <th scope="col">Tipo</th>
                                        <th scope="col">Limite</th>
                                        <th scope="col">Bloquear</th>
                                        <th scope="col">Porcentagem</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in filteredMarkets" :key="index">
                                        <td class="align-middle text-nowrap">{{item.label}}</td>
                                        <td>
                                            <select class="form-control w-auto" v-model="filteredMarkets[index].type">
                                                <option value="1" :selected="item.type == 1">Reduzir</option>
                                                <option value="2" :selected="item.type == 2">Aumentar</option>
                                            </select>
                                        </td>
                                        <td>
                                            <input class="form-control w-auto w-limit" type="text" :id="`limit-${index}`" v-model="filteredMarkets[index].limit" v-number-only @paste="noPaste($event)"> 
                                        </td>
                                        <td>
                                            <select class="form-control w-auto" v-model="filteredMarkets[index].active">
                                                <option value="true" :selected="item.active == true">Não</option>
                                                <option value="false" :selected="item.active == false">Sim</option>
                                            </select>
                                        </td>
                                        <td>
                                            <input class="form-control w-auto" type="text" :id="`percent-${index}`" v-model="filteredMarkets[index].percent" v-number-only @paste="noPaste($event)"> 
                                        </td>
                                    </tr>
                                </tbody>
                            </table> 
                        </div><!-- /div -->
                    </div><!-- /mt-3 mb-3 -->
                </div><!-- /div -->
            </div><!-- /container -->
        </div><!-- /main -->
      <loading :active.sync="bg_loading" :can-cancel="false" :is-full-page="true"></loading>
    </main>
</template>

<script>
import {api} from '../api'
import Navbar from './Navbar'
import Swal from 'sweetalert2'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
    data() {
        return {
            cat: 90,
            sport: 1,
            error: '',
            search: '',
            loading: false,
            bg_loading: false,
            platform: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 'mobile' : 'desktop',
            network_erros: {
				0: {
					message: 'Não há conexão com a internet!'
				},
				404: {
					message: 'Página solicitada não foi encontrada!'
				},
				500: {
					message: 'O servidor está passando por problemas técnicos!'
                },
                401: {
                    message: 'Você não tem autorização para realizar essa operação!'
                },
				408: {
					message: 'Por favor, verifique sua conexão de rede e tente novamente!'
				}					
            },
            markets: []
        }
    },
    components: {
        Navbar,
        Loading
    },
    methods: {
        save() {
            
            const self = this;
            const regExp = /[a-zA-Z]/g;
            const filter = self.markets.some(item => item.limit.toString().trim().length == 0 || item.percent.toString().trim().length == 0 || regExp.test(item.limit.toString().trim()) || regExp.test(item.percent.toString().trim()));

            if (filter) {
                Swal.fire(
                    'Atenção',
                    'Preencha todos os campos, utilizando apenas números!',
                    'warning'
                );
            } 
            else 
            {

                self.bg_loading = true;
                
                api.post('dashboard/odds', {
                    markets: self.markets
                }).then((response) => {
                    switch(response.data.result) {
                        case 'success':
                            Swal.fire(
                                'Parabéns!',
                                'As alterações foram salvas com sucesso!',
                                'success'
                            );
                        break;
                        default:
                            Swal.fire(
                                'Falha!',
                                response.data.message,
                                'error'
                            );
                    }
                }).catch((error) => {
                    try {
                        Swal.fire(
                            'Falha',
                            self.network_erros[error.status]['message'],
                            'error'
                        );
                    } catch(e) {
                        Swal.fire(
                            'Falha',
                            self.network_erros[408]['message'],
                            'error'
                        );
                    }
                }).finally(() => {
                    self.bg_loading = false;
                });
            }
        },
        async controlGeneral() {

            const self = this;
            const periodMap = {0: 0, 1: '90', 2: 'pt', 3: 'st'};

            const { value: formValues } = await Swal.fire({
                title: 'Controle geral',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Alterar',
                html: `
                    <label class="d-block text-left mb-0">Esporte</label>
                    <select id="swal-input1" class="swal2-input w-100">
                        <option value="0">Todos</option>
                        <option value="1">Futebol</option>
                        <option value="2">Basquete</option>
                        <option value="3">Lutas</option>
                        <option value="4">Hóquei</option>
                        <option value="5">Voleibol</option>
                        <option value="6">Tênis</option>
                        <option value="7">E-Sports</option>
                    </select>

                    <label class="d-block text-left mb-0">Período</label>
                    <select id="swal-input2" class="swal2-input w-100" ${self.sport > 1 ? 'disabled' : ''}>
                        <option value="0">Todos</option>
                        <option value="1" ${self.sport > 1 ? 'selected' : ''}>Tempo completo</option>
                        <option value="2">Primeiro tempo</option>
                        <option value="3">Segundo tempo</option>
                    </select>

                    <label class="d-block text-left mb-0">Cotação</label>
                    <select id="swal-input3" class="swal2-input w-100">
                        <option value="1" selected>Reduzir</option>
                        <option value="2">Aumentar</option>
                    </select>

                    <label class="d-block text-left mb-0">Limite</label>
                    <input id="swal-input4" class="swal2-input" value="30" autocomplete="off" spellcheck="false">

                    <label class="d-block text-left mb-0">Bloquear</label>
                    <select id="swal-input5" class="swal2-input w-100">
                        <option value="true" selected>Não</option>
                        <option value="false">Sim</option>
                    </select>

                    <label class="d-block text-left mb-0">Porcentagem</label>
                    <input id="swal-input6" class="swal2-input" value="0" autocomplete="off" spellcheck="false">
                `,
                focusConfirm: false,
                preConfirm: () => {
                    return {
                        tipo: document.getElementById('swal-input3').value,
                        esporte: document.getElementById('swal-input1').value,
                        periodo: document.getElementById('swal-input2').value,
                        limite: document.getElementById('swal-input4').value,
                        bloquear: document.getElementById('swal-input5').value,
                        porcentagem: document.getElementById('swal-input6').value
                    }
                },
                onOpen: () => {
                    document.getElementById("swal-input1").addEventListener("change", (e) => {

                        try {

                            var sport = e.target.value;
                            var el = document.querySelector('#swal-input2');

                            if (el && sport > 1) {
                                el.setAttribute('disabled', true);
                            } else {
                                el.removeAttribute('disabled');
                            }

                            if (sport == 0) sport = 1;

                            return self.sport = sport;
                        } catch(e) {
                            window.console.log(e.message);
                        }
                    });

                    document.getElementById("swal-input4").addEventListener("input", (e) => {
                        return e.target.value = self.formatNumber(e.target.value);
                    });

                    document.getElementById("swal-input6").addEventListener("input", (e) => {
                        return e.target.value = self.formatNumber(e.target.value);
                    });
                }
            });

            if (formValues instanceof Object) {
                
                if (formValues.limite == '' || formValues.porcentagem == '') {
                    Swal.fire(
                        'Atenção!',
                        'Preencha todos os campos!',
                        'warning'
                    );
                }
                else
                {
                    for (let index in self.markets) {
                        let market = self.markets[index];
                        let market_cat = periodMap[formValues.periodo];

                        if (formValues.esporte == 0 && market_cat == 0) {
                            market.type = formValues.tipo;
                            market.limit = formValues.limite;
                            market.active = formValues.bloquear;
                            market.percent = formValues.porcentagem;
                        } else if (formValues.esporte == 0 && market.cat == market_cat) {
                            market.type = formValues.tipo;
                            market.limit = formValues.limite;
                            market.active = formValues.bloquear;
                            market.percent = formValues.porcentagem;
                        } else if (market.sport == formValues.esporte && market_cat == 0) {
                            market.type = formValues.tipo;
                            market.limit = formValues.limite;
                            market.active = formValues.bloquear;
                            market.percent = formValues.porcentagem;                            
                        } else if (market.sport == formValues.esporte && market.cat == market_cat) {
                            market.type = formValues.tipo;
                            market.limit = formValues.limite;
                            market.active = formValues.bloquear;
                            market.percent = formValues.porcentagem;                            
                        }
                    }
                }
            }
        },
        formatNumber(value) {

           value = value.replace(/\D/g,"");  
           value = value.replace(/[0-9]{12}/," ");  
           value = value.replace(/(\d{1})(\d{8})$/,"$1.$2");
           value = value.replace(/(\d{1})(\d{5})$/,"$1.$2");  
           value = value.replace(/(\d{1})(\d{1,2})$/,"$1.$2"); 
        
           return value.replace(',','.');
        },
        noPaste(event) {
            return event.preventDefault();
        },
        marketGetSport(id) {

            var sport = 1;

            if (id >= 1 && id <= 211) {
                sport = 1;
            } else if (id >= 212 && id <= 220) {
                sport = 2;
            } else if (id >= 221 && id <= 223) {
                sport = 3;
            } else if (id >= 224 && id <= 226) {
                sport = 4;
            } else if (id >= 227 && id <= 229) {
                sport = 5;
            } else if (id >= 230 && id <= 232) {
                sport = 6;
            } else if (id >= 233 && id <= 235) {
                sport = 7;
            }

            return sport;
        },
        marketsStructure(markets) {
            return markets.map((item) => {
                return {
                    id: item.id,
                    type: item.type,
                    cat: item.cat,
                    limit: item.limit,
                    percent: item.percent,
                    active: item.active,
                    label: item.label,
                    sport: this.marketGetSport(item.id)
                }
            });
        },
    },
    watch: {
        sport() {
            this.cat = 90;
        }
    },
    computed: {
        filteredMarkets() {
            return this.markets.filter((item) => {
                return item.sport == this.sport && item.cat == this.cat && item.label.toLowerCase().trim().includes(this.search.toLowerCase().trim())
            });
        }
    },
    directives: {
        numberOnly: {
            bind: (el) => {
                el.handler = () => {
                    el.value = el.value.replace(/[^0-9.]/g,"");
                }
                el.addEventListener('input', el.handler)
            },
            unbind: (el) => {
                el.removeEventListener('input', el.handler)
            }
        }
    },
    created() {

        const self = this;

        self.loading = true;

        api.get('dashboard/odds').then((response) => {
            switch(response.data.result) {
                case 'success':
                    self.markets = self.marketsStructure(response.data.markets);
                break;
                default:
                    self.error = response.data.message;
                break;
            }
        }).catch((error) => {
            try {
                self.error = self.network_erros[error.status]['message'];
            } catch(e) {
                self.error = self.network_erros[408]['message'];
            }
        }).finally(() => {
            self.loading = false;
        });

        window.addEventListener('resize', () => {
            self.platform = this.isMobile();
        });
    }
}
</script>

<style scoped>
.main {
    margin-top: 80px;
}
.d-contents {
    display: contents!important;
}
.form-control.w-auto {
    width: auto!important;
}
</style>